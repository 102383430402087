import tools from "../../../extends/tools";
import apis from "../../common/js/api2";
export default {
    init() {
        //获取数据源
        apis.allotSource().then(res => {
            if (tools.msg(res, this)) {
                for (let i in res.data) {
                    if (res.data[i]) {
                        this.$set(this.source, i, res.data[i]);
                    } else {
                        this.$set(this.source, i, []);
                    }
                }
            }
        }).catch(err => {
            tools.err(err, this);
        });
        //如果是编辑，则获取详情
        let id = this.$route.query.id;
        if (id > 0) {
            //查看详情禁止编辑
            if (this.$route.query.read == 1) {
                this.isRead = true;
            }
            apis.allotDetail({
                id: id
            }).then(res => {
                if (tools.msg(res, this)) {
                    this.initData(res.data);
                }
            }).catch(err => {
                tools.err(err, this);
            });
        }
    },
    initData(data) {
        let goods = [];
        data.goods_info.forEach((item, key) => {
            for (let i in item) {
                if (i === 'price' || i === 'subtotal') {
                    item[i] = tools.setPrice(item[i]);
                }
            }
            item.retail_price = item.price;
            item.id = item.details_id;
            item.name = item.goods_name;
            item.unit = item.goods_unit_name;
            item.specification_code = item.product_code;
            item.pic = item.specifications_pic;
            item.dispose_info = item.specifications.join(',');

            item.num = item.out_available;
            item.remarks = item.remarks;
            item.actual = 1;
            //入库方库存
            item.in_num = item.in_available;
            item.goods_specifications_id = item.goods_specifications_id;

            goods.push(item);
        });
        this.goodsList = goods;

        this.saveData = {
            out_warehouse_id: data.out_warehouse_id,
            in_warehouse_id: data.in_warehouse_id,
            business_date: data.business_date,
            remarks: data.remarks,
            user_id: data.user_id,
            status: 0,
            enclosure: [],
            goods: []
        }

        this.enclosure = data.enclosure
            //展示数据
        this.showData = data;
        this.changeSum();
    },
    changeSum() {
        this.sumData.sumNum = 0;
        this.goodsList.forEach((item, key) => {
            this.sumData.sumNum += item.num;
        });
    },
    cancel() {
        history.back();
    },
    exportData() {

    },
    // 获取上传的地址
    uploadFile(file) {
        if (file.code === 200) {
            this.enclosure.push(file.font);
        } else {
            this.$message({
                type: 'error',
                message: file.msg
            });
        }
    },
    saveSelect(selectData) {
        if (selectData.length <= 0) {
            return;
        }
        selectData.forEach((item, key) => {
            let obj = {};
            obj.specification_code = item.specification_code;
            obj.pic = item.pic;
            obj.name = item.goods_info.name;
            obj.dispose_info = item.dispose_info.join(',');
            obj.unit = item.goods_info.unit_info.name;
            obj.num = item.inventory;
            obj.remarks = '';
            obj.actual = 1;
            //入库方库存
            obj.in_num = item.in_available;
            obj.goods_specifications_id = item.id;
            //判断是否有有相同的
            let flag = true;
            this.goodsList.forEach((i, k) => {
                if (!!i.goods_specifications_id && item.id === i.goods_specifications_id) {
                    i.num = i.num + 1;
                    i.sum = tools.toDecimal2(i.retail_price * i.num);
                    flag = false;
                    this.$message({
                        type: 'error',
                        message: '有相同商品，已合并到列表中'
                    })
                }
            });
            //
            if (flag) {
                this.goodsList.push(obj);
            }
        });

        this.changeSum();
        // console.log(this.goodsList);
    },
    shareExit() {
        this.createShareFlag = false;
    },
    pickGoods() {
        if (this.saveData.out_warehouse_id === '') {
            this.$message({
                type: 'error',
                message: '请先选择一个出库仓库'
            });
            return;
        }
        this.createShareFlag = true;
    },
    remove() {
        let id = this.$route.query.id;
        this.$confirm('确定要删除吗?', '删除确认', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        }).then(() => {
            apis.allotDelete({ id: id }).then((info) => {
                if (info.code == 200) {
                    this.tableData.splice(this.tableData.findIndex(item => item.id === id), 1);
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                    this.$router.push({
                        path: "/allot"
                    })
                } else {
                    this.$message({
                        type: 'error',
                        message: info.msg
                    });
                }
            })
        }).catch(() => {
            this.$message({
                type: 'info',
                message: '已取消删除'
            });
        });
    },
    copyRecord() {
        let id = this.$route.query.id;
        this.$router.push({
            path: '/allot/add',
            query: {
                id: id,
                is_copy: 1
            }
        });
    },
    edit() {
        let id = this.$route.query.id;
        this.$router.push({
            path: '/allot/add',
            query: {
                id: id
            }
        });
    },
    removeLine(key) {
        if (this.goodsList.length <= 1) {
            return;
        }
        this.goodsList.splice(key, 1);
        this.changeSum();
    },
    save(status) {
        this.saveData.status = status;
        //转换列表
        // 商品信息
        let goods = [];
        if (!this.saveData.user_id) {
            this.$message({
                type: 'error',
                message: '请选择一个经手人'
            });
            return;
        }
        //附件信息
        let enclosure = [];
        this.goodsList.forEach((item, key) => {
            let obj = {
                goods_specifications_id: item.goods_specifications_id,
                num: item.actual,
                remarks: item.remarks
            };
            goods.push(obj);
        });

        this.enclosure.forEach((item, key) => {
            enclosure.push(item);
        });
        //
        this.saveData.goods = JSON.stringify(goods);
        this.saveData.enclosure = JSON.stringify(enclosure);

        //加载动画
        let loader = tools.loading(null, this);
        //获取详情
        let is_copy = this.$route.query.is_copy;
        //
        let id = this.$route.query.id;
        if (id > 0 && is_copy != 1) {
            this.saveData.id = id;
            apis.allotSave(this.saveData).then(res => {
                if (tools.msg(res, this)) {
                    this.$message({
                        type: 'success',
                        message: '修改成功'
                    })
                }
                tools.loading(loader);
                this.$router.back();
            }).catch(err => {
                tools.err(err, this);
                tools.loading(loader);
            });

            return;
        }

        apis.allotAdd(this.saveData).then(res => {
            if (tools.msg(res, this)) {
                this.$message({
                    type: 'success',
                    message: '添加成功！'
                })
                this.$router.push({
                    path: '/allot'
                });
            }
            tools.loading(loader);
            this.$router.back();

        }).catch(err => {
            tools.err(err, this);
            tools.loading(loader);
        });

    }
}